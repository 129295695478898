/* eslint-disable @typescript-eslint/no-explicit-any */
export const SCENARIO_OPTION_INITIAL_VALUES: any = {
	buttonText: '',
	url: '',
	nextScenario: '',
};

export const SCENARIO_ITEM_INITIAL_VALUES: any = {
	text: '',
	photo: '',
	options: [],
	// level: '0',
	parentId: '',
	realIndex: 0,
	isNew: true,
};

export const SCENARIO_INITIAL_VALUES: any = {
	name: null,
	description: null,
	body: [{ intro: { ...SCENARIO_ITEM_INITIAL_VALUES } }],
};

export const SETTINS_INITIAL_VALUES: any = {
	botName: '',
	botToken: '',
};
