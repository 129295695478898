/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { AuthContainer, GuardedRoute, MainContainer } from 'containers';
import {
	CreateNewPage,
	HomePage,
	LoginPage,
	NotFoundPage,
	SettingsPage,
	SignupPage,
	UpdatePage,
	BulkSendPage,
} from 'pages';

const App: FC = () => (
	<Router>
		<Routes>
			<Route path="*" element={<NotFoundPage />} />
			{/* AUTH */}
			<Route path={`${ROUTES.LOGIN}/*`} element={<AuthContainer />}>
				<Route index element={<LoginPage />} />
				<Route path={ROUTES.RESTORE_PASSWORD} element={<div>In progress..................</div>} />
			</Route>

			<Route path={`${ROUTES.SIGN_UP}/*`} element={<AuthContainer />}>
				<Route index element={<SignupPage />} />
			</Route>

			{/* COMMON */}
			<Route path={`${ROUTES.MAIN}*`} element={<MainContainer />}>
				<Route
					index
					element={
						<GuardedRoute>
							<HomePage />
						</GuardedRoute>
					}
				/>
			</Route>
			<Route path={ROUTES.NEW_SCENARIO} element={<MainContainer />}>
				<Route
					index
					element={
						<GuardedRoute>
							<CreateNewPage />
						</GuardedRoute>
					}
				/>
			</Route>
			<Route path={`${ROUTES.UPDATE}/:scenarioId`} element={<MainContainer />}>
				<Route
					index
					element={
						<GuardedRoute>
							<UpdatePage />
						</GuardedRoute>
					}
				/>
			</Route>
			<Route path={ROUTES.BULK_SEND} element={<MainContainer />}>
				<Route
					index
					element={
						<GuardedRoute>
							<BulkSendPage />
						</GuardedRoute>
					}
				/>
			</Route>
			<Route path={ROUTES.SETTINGS} element={<MainContainer />}>
				<Route
					index
					element={
						<GuardedRoute>
							<SettingsPage />
						</GuardedRoute>
					}
				/>
			</Route>
		</Routes>
	</Router>
);

export default App;
