import { AnyAction, Reducer } from '@reduxjs/toolkit';
import { ISettingsReducer } from 'utils/types';
import { ACTION_TYPES } from './actions';

const initialState: ISettingsReducer = {
	isLoading: false,
};

export const settingsReducer: Reducer<ISettingsReducer> = (
	state: ISettingsReducer = initialState,
	action: AnyAction
) => {
	const { type, payload } = action;

	switch (type) {
		case ACTION_TYPES.SETTINGS_LOADING:
			return {
				...state,
				isLoading: payload,
			};
		default:
			return state;
	}
};
