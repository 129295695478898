import { AxiosResponse } from 'axios';
import { API } from 'utils/api';
import { MULTIMEDIA_API_URL } from 'utils/constants';

class MultimediaService {
	static uploadFile = (image: File): Promise<AxiosResponse<string>> => {
		const body = new FormData();
		body.append('image', image);
		return API.post(MULTIMEDIA_API_URL.UPLOAD_FILE, body);
	};
}

export { MultimediaService };
