/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
import { BotScenarioField } from 'components/Fields';

export const CreateNew = () => {
	return (
		<>
			<BotScenarioField />
		</>
	);
};
