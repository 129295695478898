import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { getUserIsLoadingSelector, signupUser } from 'store/user';
import { CustomButton, CustomInput } from 'components/shared';
import { COMMON_FIELD_NAMES } from 'utils/constants';
import { IUserRequest } from 'utils/types';
import { signupValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

const USER_INITIAL_VALUES: IUserRequest = {
	email: '',
	password: '',
};

export const SignupForm: FC = () => {
	const isLoading = useSelector(getUserIsLoadingSelector);
	const dispatch = useDispatch();

	const handleSubmit = async (values: IUserRequest): Promise<void> => {
		await dispatch(signupUser(values));
	};

	return (
		<Formik initialValues={USER_INITIAL_VALUES} onSubmit={handleSubmit} validationSchema={signupValidationSchema}>
			<Form>
				<div className={styles.field}>
					<Field
						placeholder="Email"
						label="Email"
						id="email"
						name={COMMON_FIELD_NAMES.EMAIL}
						component={CustomInput}
						isShowErrorText
					/>
				</div>
				<div className={styles.field}>
					<Field
						placeholder="Password"
						label="Password"
						id="password"
						name={COMMON_FIELD_NAMES.PASSWORD}
						type="password"
						component={CustomInput}
						isShowErrorText
					/>
				</div>
				<CustomButton
					sx={{ marginTop: '12px', width: '100%' }}
					fullWidth
					label="Create Account"
					type="submit"
					disabled={isLoading}
					className={styles.button_login}
				/>
			</Form>
		</Formik>
	);
};
