/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-brace-presence */
import { FC } from 'react';
import { Typography } from '@mui/material';
import { extractUsernameFromEmail } from 'utils/helpers';
import styles from './index.module.scss';

interface UserInfoComponentProps {
	data: any;
}

export const UserInfoComponent: FC<UserInfoComponentProps> = ({ data }) => (
	<div className={styles.wrapper}>
		<Typography variant="h5" className={styles.title}>
			{`Welcome, ${extractUsernameFromEmail(data.email)}`}
		</Typography>
		<Typography variant="h5" className={styles.subtitle}>
			Current bot informarion:
		</Typography>
		<div className={styles.item}>
			<Typography>{`Name: ${data.botName}`}</Typography>
			<Typography>{`Token: ${data.botToken}`}</Typography>
			<div className={styles.item__status}>
				<Typography>{`Status: ${' '}`}</Typography>
				<Typography
					className={
						data.favoriteScenario ? styles.item__status__indication_active : styles.item__status__indication_disable
					}
				>
					{data.favoriteScenario ? 'active' : 'disabled'}
				</Typography>
			</div>
		</div>
	</div>
);
