import { FC, ReactNode } from 'react';
import { ButtonProps, IconButton } from '@mui/material';

interface ICustomIconButtonProps extends ButtonProps {
	icon: ReactNode;
}

export const CustomIconButton: FC<ICustomIconButtonProps> = ({ icon, ...props }) => (
	<IconButton {...props}>{icon} </IconButton>
);
