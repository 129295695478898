/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { API } from 'utils/api';
import { SCENARIO_API_URL, VAULT_SERVICE_KEYS } from 'utils/constants';
import { IBotScenario } from 'utils/types';
import { VaultService } from './vault.service';

class ScenarioService {
	private userId: string | null;

	constructor() {
		this.userId = VaultService.getItem<string>(VAULT_SERVICE_KEYS.USER_ID);
	}

	static getAllScenarios = async (): Promise<AxiosResponse<IBotScenario>> => {
		const instance = new ScenarioService();
		return API.get(`${SCENARIO_API_URL.ALL_SCENARIOS}${instance.userId}`);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static postActiveScenario = async (reqBody: any): Promise<AxiosResponse<IBotScenario>> => {
		const instance = new ScenarioService();
		return API.post(`${SCENARIO_API_URL.FAVORITE_SCENARIO}${instance.userId}`, reqBody);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static postNewScenario = async (reqBody: any): Promise<AxiosResponse<any>> => {
		const instance = new ScenarioService();
		return API.post(`${SCENARIO_API_URL.BY_ID}${instance.userId}`, reqBody);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static updateScenario = async (reqBody: any, id: any): Promise<AxiosResponse<any>> =>
		API.put(`${SCENARIO_API_URL.BY_ID}${id}`, reqBody);

	static deleteScenario = async (id: any): Promise<AxiosResponse<any>> => API.delete(`${SCENARIO_API_URL.BY_ID}${id}`);
}

export { ScenarioService };
