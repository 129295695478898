import { FC, useState } from 'react';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { AxiosResponse } from 'axios';
import { CustomButton, CustomQuill } from 'components/shared';

import styles from './index.module.scss';
import { newBulk } from '../../../store/user';
import { UploadFile } from '../../UploadFile';
import { MultimediaService, UserService } from '../../../utils/services';
import { removePortFromURL, toastifyMessage } from '../../../utils/helpers';
import { COMMON_MESSAGE, TOASTIFY_TYPES, USER_MESSAGE } from '../../../utils/constants';

interface BulkSendFormComponentProps {
	data: any;
}

export const BulkSendFormComponent: FC<BulkSendFormComponentProps> = ({ data }) => {
	const dispatch = useDispatch();
	const [imgUrl, setImgUrl] = useState<null | string>(null);

	const handleUpload = async (img: File): Promise<void> => {
		try {
			const response: AxiosResponse<any> = await MultimediaService.uploadFile(img);
			const url = response.data.fileUrl;
			const cleanUrl = removePortFromURL(url);
			setImgUrl(cleanUrl);
			toastifyMessage(COMMON_MESSAGE.IMAGE, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			toastifyMessage(COMMON_MESSAGE.SOMETHING_WENT_WRONG, TOASTIFY_TYPES.ERROR);
		}
	};

	const handleSubmit = async (values: any, actions: any) => {
		try {
			// const response: any = await UserService.sendBulk({ ...values, bulkImage: imgUrl });
			await dispatch(newBulk({ ...values, bulkImage: imgUrl }));

			setImgUrl(null);
			actions.resetForm();
		} catch (error) {
			toastifyMessage(USER_MESSAGE.BULK_SEND_ERROR, TOASTIFY_TYPES.ERROR);
		}
	};

	return (
		<div className={styles.form}>
			<div>
				<Formik initialValues={{ bulkMessage: '' }} onSubmit={(values, actions) => handleSubmit(values, actions)}>
					{() => (
						<Form className={styles.wrapper}>
							<h3 className={styles.title}>New Bulk Send</h3>
							<p className={styles.subtitle}>Sending message to all your bot users</p>
							<div className={styles.imgContainer}>
								{imgUrl && (
									<div className={styles.imgWrapp}>
										<button className={styles.imgDelete} type="button" onClick={() => setImgUrl(null)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M15.625 4.375L4.375 15.625M15.625 15.625L4.375 4.375"
													stroke="white"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>

										{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
										<img src={imgUrl || ''} alt="uploaded image" />
									</div>
								)}
								<Field className={styles.upload} onChange={handleUpload} name="bulk-image" component={UploadFile} />
							</div>
							<Field className={styles.field} label="Message Text" name="bulkMessage" component={CustomQuill} />
							<Box className={styles.submit_button}>
								<CustomButton type="submit" label="Submit message" />
							</Box>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};
