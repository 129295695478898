import * as yup from 'yup';
import { COMMON_FIELD_NAMES } from 'utils/constants';

export const emailValidationSchema = yup.string().email('Must be a valid email address').required('Required');

export const passwordValidationSchema = yup
	.string()
	.min(6, 'Password must be at least 6 characters')
	.required('Required');

export const loginValidationSchema = yup.object().shape({
	[COMMON_FIELD_NAMES.EMAIL]: emailValidationSchema,
	[COMMON_FIELD_NAMES.PASSWORD]: passwordValidationSchema,
});

export const restorePasswordValidationSchema = yup.object().shape({
	[COMMON_FIELD_NAMES.PASSWORD_NEW]: passwordValidationSchema,
	[COMMON_FIELD_NAMES.PASSWORD_CONFIRM]: passwordValidationSchema.oneOf(
		[yup.ref('newPassword'), null],
		'Passwords must match'
	),
});

export const signupValidationSchema = loginValidationSchema;
