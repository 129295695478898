/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { postSettings } from 'store/settings';
import { CustomButton, CustomInput, FieldWrapper } from 'components/shared';
import { COMMON_FIELD_NAMES, SETTINS_INITIAL_VALUES } from 'utils/constants';
import { settingsValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

interface SettingsFieldProps {
	initialValues?: any;
}

export const SettingsField: FC<SettingsFieldProps> = ({ initialValues }) => {
	const dispatch = useDispatch();

	const submitHanlder = (value: any): void => {
		dispatch(postSettings(value));
	};

	return (
		<Formik
			initialValues={initialValues || SETTINS_INITIAL_VALUES}
			onSubmit={submitHanlder}
			validationSchema={settingsValidationSchema}
		>
			<Form className={styles.wrapper}>
				<FieldWrapper title="Bot information" description="Enter information about your bot" isStepper>
					<Box className={styles.top_side}>
						<Field
							name={COMMON_FIELD_NAMES.BOT_NAME}
							component={CustomInput}
							label="Name"
							id="botName"
							isShowErrorText
						/>
						<Field
							name={COMMON_FIELD_NAMES.BOT_TOKEN}
							component={CustomInput}
							label="Token"
							id="botToken"
							isShowErrorText
						/>
					</Box>
					<Box className={styles.submit_button}>
						<CustomButton type="submit" label="Save" />
					</Box>
				</FieldWrapper>
			</Form>
		</Formik>
	);
};
