import { FC } from 'react';
import { Box, ListItemButton, MenuItem, Select, SelectProps } from '@mui/material';
import { ReactComponent as ArrowSvg } from 'assets/icons/arrow.svg';
import { ReactComponent as WhiteArrowSvg } from 'assets/icons/white-arrow.svg';
import { withField } from 'hoc';
import { SELECT_TYPE } from 'utils/constants';
import { DefaultFieldProps, IOptionAdvanced } from 'utils/types';
import styles from './index.module.scss';

export interface CustomSelectProps<T>
	extends Omit<SelectProps<T>, 'error' | 'value' | 'onChange' | 'label' | 'error' | 'onOpen' | 'onClose' | 'onBlur'>,
		DefaultFieldProps<T> {
	options: IOptionAdvanced<T>[];
	isDefault: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectComponent: FC<CustomSelectProps<any>> = ({
	options,
	value,
	placeholder,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onDelete,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	form,
	isDefault,
	defaultValue = '',
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any

	const styleSelector = (viewType: SELECT_TYPE): string | undefined => {
		switch (viewType) {
			case SELECT_TYPE.ACTIVE:
				return styles.select__green;
			case SELECT_TYPE.NOT_ACTIVE:
				return styles.select__red;
			default:
				return undefined;
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const viewVariantDefinitor = () => options?.find((item: IOptionAdvanced<any>) => item.value === value)?.viewVariant;

	const titleSelector = () =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		options?.find((item: IOptionAdvanced<any>) => item.value === value)?.title || placeholder || 'select' || '';

	const currentTitle = titleSelector();
	const currentViewVariant = viewVariantDefinitor();

	return (
		<Select
			{...props}
			value={value ?? ''}
			placeholder={placeholder || ''}
			IconComponent={isDefault ? ArrowSvg : WhiteArrowSvg}
			defaultValue={defaultValue}
			displayEmpty
			inputProps={{ MenuProps: { disableScrollLock: true } }}
			className={currentViewVariant && styleSelector(currentViewVariant)}
			renderValue={
				props.renderValue ||
				(value => (
					<ListItemButton disabled={value === undefined || value === null || value === ''}>
						{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
						{options.find((item: IOptionAdvanced<any>) => item.value === value)?.icon}
						<Box className={!isDefault ? styles.select__inner : undefined}>
							{!isDefault ? <Box className={styles.select__container}>{currentTitle}</Box> : currentTitle}
						</Box>
					</ListItemButton>
				))
			}
		>
			<MenuItem sx={{ display: 'none' }} value={defaultValue}>
				<span>{placeholder}</span>
			</MenuItem>
			{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
			{options.map(({ value, icon, unSelectedIcon, title }: IOptionAdvanced<any>) => (
				<MenuItem key={title} value={value} disableRipple className={styles.dropdown_item}>
					{unSelectedIcon || icon}
					<span>{title}</span>
				</MenuItem>
			))}
		</Select>
	);
};

export const CustomSelect = withField(SelectComponent);
