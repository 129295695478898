import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Experimental_CssVarsProvider as CssVarsProvider, StyledEngineProvider } from '@mui/material';
import type {} from '@mui/material/themeCssVarsAugmentation';
import { theme } from './utils/theme';
import App from './App';
import { store } from './store';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<StyledEngineProvider injectFirst>
		<CssVarsProvider theme={theme}>
			<Provider store={store}>
				<App />
				<ToastContainer className="toast" hideProgressBar pauseOnHover={false} draggable={false} limit={3} />
			</Provider>
		</CssVarsProvider>
	</StyledEngineProvider>
);
