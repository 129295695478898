/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllScenarioData, getAllScenarioDataSelector } from 'store/scenario';
import { BotScenarioField } from 'components/Fields';
import { IBotScenario } from 'utils/types';

interface UpdateScenarioProps {
	scenarioId: any;
}

export const UpdateScenario: FC<UpdateScenarioProps> = ({ scenarioId }) => {
	const dispatch = useDispatch();

	const allScenarios: IBotScenario[] | null = useSelector(getAllScenarioDataSelector);
	const activeScenario = allScenarios?.find(item => item._id === scenarioId);

	useEffect(() => {
		dispatch(getAllScenarioData());
	}, []);

	return <>{!!activeScenario && <BotScenarioField initialValues={activeScenario} scenarioId={scenarioId} />}</>;
};
