export const extractUsernameFromEmail = (email: string): string | null => {
	if (email) {
		const regex = /^([^@]+)/;
		const match = email.match(regex);

		if (match && match[1]) {
			return match[1];
		}
	}

	return null;
};
