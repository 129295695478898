/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { postActiveScenario } from 'store/scenario';
import { CustomButton } from 'components/shared';
import { SELECT_TYPE, STATUS_SELECT_OPTIONS } from 'utils/constants';
import { dateTransformer } from 'utils/helpers';
import styles from './index.module.scss';

interface ActiveScenarioComponentProps {
	data: any;
	onChange: any;
	status: any;
}

export const ActiveScenarioComponent: FC<ActiveScenarioComponentProps> = ({ data, onChange, status }) => {
	const dispatch = useDispatch();

	const statusHandler = () => {
		dispatch(postActiveScenario({ favoriteScenarioId: null }));
		setTimeout(() => {
			onChange(!status);
		}, 400);
	};

	return (
		<div className={styles.wrapper}>
			{data ? (
				<>
					<div>
						<Typography variant="h5" className={styles.title}>
							Current scenario informarion:
						</Typography>
						<Typography className={styles.current_scenario_text}>{`Name: ${data.name}`}</Typography>
						<Typography className={styles.current_scenario_text}>{`Description: ${data.description}`}</Typography>
						<Typography className={styles.current_scenario_text}>{`Created date: ${dateTransformer(
							data.createdAt
						)}`}</Typography>
						<Typography className={styles.current_scenario_text}>{`Updated date: ${dateTransformer(
							data.updatedAt
						)}`}</Typography>
					</div>
					<div className={styles.select}>
						<CustomButton className={styles.button} onClick={statusHandler} label="Turn Off" />
					</div>
				</>
			) : (
				<Typography className={styles.not_active}>No active scenario, bot disabled</Typography>
			)}
		</div>
	);
};
