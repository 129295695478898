import { FC } from 'react';
import { CreateNew } from 'components';
import styles from './index.module.scss';

export const CreateNewPage: FC = () => (
	<div className={styles.content}>
		<div className={styles.wrapper}>
			<CreateNew />
		</div>
	</div>
);
