/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, FC, HTMLAttributes } from 'react';
import { Box, InputLabel, Theme, Typography, useTheme } from '@mui/material';
import classnames from 'classnames';
import { withField } from 'hoc';
import { DefaultFieldProps } from 'utils/types';
import styles from './index.module.scss';

type UploadFileProps<T> = HTMLAttributes<HTMLInputElement> & DefaultFieldProps<T>;

export const UploadFileComponent: FC<UploadFileProps<string>> = ({
	onChange,
	id,
	placeholder = 'Upload image',
	value,
	onBlur,
	error,
	name,
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const fileList: FileList = event.target.files as FileList;
		if (onChange) {
			onChange(fileList[0]);
		}
	};

	return (
		<>
			<InputLabel
				htmlFor={id ?? name}
				className={classnames(styles.wrapper, {
					[styles.wrapper_empty]: !value,
					[styles.wrapper_error]: error,
				})}
			>
				<Typography className={styles.title}>{placeholder}</Typography>
			</InputLabel>
			<input
				className={styles.input_hidden}
				onBlur={onBlur}
				type="file"
				accept="image/*"
				id={id}
				onChange={handleChange}
				name={name}
			/>
		</>
	);
};

export const UploadFile = withField(UploadFileComponent);
