import { FC, FormEvent, MouseEvent } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { BUTTON_VARIANT } from 'utils/constants';

interface CustomButtonProps extends Omit<ButtonProps, 'onClick'> {
	label: string;
	onClick?:
		| ((event: FormEvent<HTMLFormElement> | undefined) => void)
		| ((event: MouseEvent<HTMLButtonElement>) => void);
}

export const CustomButton: FC<CustomButtonProps> = ({
	label,
	variant = BUTTON_VARIANT.CONTAINED,
	onClick,
	...props
}) => (
	<Button {...props} onClick={onClick as (event: MouseEvent<HTMLButtonElement>) => void} variant={variant}>
		{label}
	</Button>
);
