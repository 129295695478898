import { AnyAction, Reducer } from '@reduxjs/toolkit';
import { IUserReducer } from 'utils/types';
import { ACTION_TYPES } from './actions';

const initialState: IUserReducer = {
	data: null,
	isLoading: false,
};

export const userReducer: Reducer<IUserReducer> = (state: IUserReducer = initialState, action: AnyAction) => {
	const { type, payload } = action;

	switch (type) {
		case ACTION_TYPES.USER_LOADING:
			return {
				...state,
				isLoading: payload,
			};
		case ACTION_TYPES.USER_GET_DATA:
			return {
				...state,
				isLoading: false,
				data: payload,
			};

		default:
			return state;
	}
};
