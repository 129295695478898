/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { Typography } from '@mui/material';
import { ScenarioPreview } from 'components/shared';
import { IBotScenario } from 'utils/types';
import styles from './index.module.scss';

interface MyScenariosProps {
	data: any;
	onChange: any;
	status: any;
	botStatus: any;
}

export const MyScenarios: FC<MyScenariosProps> = ({ data, onChange, status, botStatus }) => (
	<div className={styles.wrapper}>
		<Typography variant="h5" className={styles.title}>
			List of scenarios
		</Typography>
		<div className={styles.inner}>
			{data
				? data.map((item: IBotScenario) => (
						<ScenarioPreview onChange={onChange} data={item} key={item._id} status={status} botStatus={botStatus} />
				  ))
				: null}
		</div>
		{!data?.length && <Typography>There`s not any scenarios, please create new</Typography>}
	</div>
);
