/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router';
import { ROUTES } from 'config/routes';
import { getUserData } from 'store/user/actions';
import { getUserDataSelector, getUserIsLoadingSelector } from 'store/user/selectors';
import { Loader } from 'components/shared';

interface IGuardedRouteProps {
	children?: ReactElement;
}

export const GuardedRoute: FC<IGuardedRouteProps> = ({ children }) => {
	const [isHasAccess, setIsHasAccess] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	// get user data from store
	const user: any | null = useSelector(getUserDataSelector);
	const userIsLoading: boolean = useSelector(getUserIsLoadingSelector);

	useEffect(() => {
		if (user?.email === undefined) {
			getUser();
			return;
		}

		if (user?.email !== undefined) {
			setIsHasAccess(true);
		} else {
			navigate(ROUTES.LOGIN);
		}
	}, [user?.email]);

	const getUser = async (): Promise<void> => {
		try {
			await dispatch(getUserData());
		} catch (error) {
			navigate(ROUTES.LOGIN);
		}
	};

	if (!isHasAccess && userIsLoading) return <Loader />;
	return isHasAccess ? children || <Outlet /> : null;
};
