/* eslint-disable no-plusplus */
interface Element {
	[key: string]: {
		id: string;
		options: { buttonText: string; url: string; nextScenario: string; id: string }[];
		parentId: string;
		photo: string;
		realIndex: number;
		text: string;
	};
}

export const deleteElementAndChildren = (arr: Element[], index: number): Element[] => {
	const deletedElement = arr[index];
	const deletedId = Object.values(deletedElement)[0]?.id;

	arr.splice(index, 1);

	const deletedIds = new Set([deletedId]);

	for (let i = index; i < arr.length; i++) {
		const currentElement = arr[i];
		const parentId = currentElement[Object.keys(currentElement)[0]]?.parentId;

		if (deletedIds.has(parentId)) {
			const deletedId = Object.keys(currentElement)[0];
			deletedIds.add(deletedId);
			arr.splice(i, 1);
			i--;
		}
	}

	for (let i = 0; i < arr.length; i++) {
		const currentElement = arr[i];
		const options = Object.values(currentElement)[0]?.options;

		if (options) {
			const updatedOptions = options.filter(option => option.id !== deletedId);

			Object.values(currentElement)[0].options = updatedOptions;
		}
	}

	for (let i = 0; i < arr.length; i++) {
		const currentElement = arr[i];
		currentElement[Object.keys(currentElement)[0]].realIndex = i;
	}

	return arr;
};
