import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getUserDataSelector, getUserIsLoadingSelector } from 'store/user';
import { LoginForm } from 'components/Auth';
import { Loader } from 'components/shared';
import { getMainRoute, getSignupRoute } from 'utils/helpers';
import { IUserResponse } from 'utils/types';
import styles from './index.module.scss';

export const LoginPage: FC = () => {
	const navigate = useNavigate();
	// const accessToken = VaultService.getItem<string>(VAULT_SERVICE_KEYS.ACCESS_TOKEN);
	const isLoading: boolean = useSelector(getUserIsLoadingSelector);
	const userData: IUserResponse | null = useSelector(getUserDataSelector);
	useEffect(() => {
		if (!isLoading && userData?.userId !== undefined) {
			navigate(getMainRoute());
		}
	}, [isLoading, userData]);

	return (
		<div className={styles.wrapper}>
			<Typography variant="h3">Log in</Typography>
			<Typography className={styles.description} variant="body1">
				Welcome! Please enter your details.
			</Typography>
			<LoginForm />
			<Typography className={styles.subtitle} variant="body1">
				Don’t have an account yet?
			</Typography>
			<Link to={getSignupRoute()}>
				<Typography variant="button">Create account</Typography>
			</Link>
			{isLoading && <Loader />}
		</div>
	);
};
