import { useState } from 'react';
import { IUseModal } from 'utils/types';

export const useModal = (openDefault = false): IUseModal => {
	const [isOpen, setIsOpen] = useState<boolean>(openDefault);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	return [isOpen, handleOpen, handleClose];
};
