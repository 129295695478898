/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { AxiosResponse } from 'axios';
import { Field, useFormikContext } from 'formik';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-can.svg';
import { CustomIconButton, CustomInputMask, CustomQuill } from 'components/shared';
import { UploadFile } from 'components/UploadFile';
import { COMMON_FIELD_NAMES, COMMON_MESSAGE, MASK, TOASTIFY_TYPES } from 'utils/constants';
import { removePortFromURL, toastifyMessage } from 'utils/helpers';
import { MultimediaService } from 'utils/services';
import { ScenarioButtonsField } from '../ScenarioButtonsField';
import styles from './index.module.scss';

interface ScenarioFormProps {
	value: any;
	replace: any;
	isIntro: boolean;
}

export const ScenarioForm: FC<ScenarioFormProps> = ({ value, replace, isIntro }) => {
	const { setFieldValue } = useFormikContext<any>();

	const handleUploadLogo = async (img: File): Promise<void> => {
		try {
			const response: AxiosResponse<any> = await MultimediaService.uploadFile(img);
			const url = response.data.fileUrl;
			const cleanUrl = removePortFromURL(url);
			setFieldValue(COMMON_FIELD_NAMES.SCENARIO_ITEM_PHOTO, cleanUrl);
			toastifyMessage(COMMON_MESSAGE.IMAGE, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			toastifyMessage(COMMON_MESSAGE.SOMETHING_WENT_WRONG, TOASTIFY_TYPES.ERROR);
		}
	};

	const deletePhoto = () => {
		setFieldValue(COMMON_FIELD_NAMES.SCENARIO_ITEM_PHOTO, '');
		toastifyMessage(COMMON_MESSAGE.IMAGE_DELETE, TOASTIFY_TYPES.SUCCESS);
	};

	return (
		<div className={styles.form}>
			<Field
				className={styles.width_full}
				label="Message Text"
				name={COMMON_FIELD_NAMES.SCENARIO_ITEM_TEXT}
				component={CustomQuill}
			/>
			<Field
				className={styles.width_half_first}
				label="Delay"
				name={COMMON_FIELD_NAMES.SCENARIO_ITEM_DELAY}
				component={CustomInputMask}
				mask={MASK.NUMBER}
				placeholder="0"
				disabled={isIntro}
			/>
			<Field
				className={styles.upload}
				label="Image"
				onChange={handleUploadLogo}
				name={COMMON_FIELD_NAMES.SCENARIO_ITEM_PHOTO}
				component={UploadFile}
			/>
			{!!value.photo && (
				<div style={{ width: '36px', height: '36px', alignSelf: 'end', marginBottom: '10px' }}>
					<CustomIconButton icon={<TrashIcon />} onClick={deletePhoto} />
				</div>
			)}
			<div className={styles.width_full}>
				<ScenarioButtonsField name={COMMON_FIELD_NAMES.SCENARIO_ITEM_OPTIONS} value={value} action={replace} />
			</div>
		</div>
	);
};
