/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType, FC, useEffect, useState } from 'react';
import { Box, FormHelperText, FormLabel, Theme, useTheme } from '@mui/material';
import { getIn } from 'formik';
import { useOpen } from 'utils/hooks';
import { DefaultFieldProps, IOptionAdvanced, IUseOpen } from 'utils/types';

export const withField =
	<T extends DefaultFieldProps<any> & Omit<IOptionAdvanced<T>, 'disabled' | 'value'>>(
		Component: ComponentType<T>
	): FC<T> =>
	({
		field,
		id,
		label,
		disabled,
		form,
		error,
		customError,
		onDelete,
		sx,
		className,
		options,
		title,
		description,
		isShowErrorText = false,
		...props
	}: T) => {
		const theme: Theme = useTheme();

		const [errorText, setErrorText] = useState<string>('');

		const { open, onClose, onOpen }: IUseOpen = useOpen();

		useEffect(() => {
			if (customError !== undefined) {
				setErrorText(customError);
			} else if (form && field) {
				const message: string | undefined = getIn(form.errors, field.name);
				const isTouched: boolean | undefined = getIn(form.touched, field.name);
				setErrorText(message && !!isTouched ? message : '');
			}
		}, [customError, form, field]);

		const handleClose = (): void => {
			// remove focus from select
			setTimeout(() => {
				(document.activeElement as HTMLInputElement).blur();
				if (field && form) {
					form.setFieldTouched(field.name, true);
				}
			});
			onClose();
		};

		const handleDelete = (value: T): void => {
			if (onDelete) {
				onDelete(value);
			}
			if (form && field) {
				form.setFieldValue(field.name, value);
			}
		};

		return (
			<Box
				className={className}
				sx={{
					position: 'relative',
					width: '100%',
				}}
			>
				{label && (
					<FormLabel sx={sx} error={!!errorText} disabled={disabled} htmlFor={id ?? field?.name}>
						{label}
					</FormLabel>
				)}
				<Component
					{...field}
					{...(props as T)}
					title={title}
					description={description}
					options={options}
					form={form}
					field={field} // Pass the field object explicitly
					sx={sx}
					id={id ?? field?.name}
					open={open}
					onClose={handleClose}
					onOpen={onOpen}
					onDelete={handleDelete}
					disabled={disabled}
					error={error || !!errorText}
				/>
				{!!errorText && isShowErrorText && (
					<FormHelperText
						sx={{
							position: 'absolute',
							top: '100%',
							right: '0',
							color: theme.vars.palette.signalRed,
							'&:first-letter': { textTransform: 'capitalize' },
						}}
					>
						{errorText}
					</FormHelperText>
				)}
			</Box>
		);
	};
