/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, FC, ReactNode } from 'react';
import InputMask, { Props } from 'react-input-mask';
import { BaseTextFieldProps } from '@mui/material';
import { withField } from 'hoc';
import { MASK } from 'utils/constants';
import { DefaultFieldProps } from 'utils/types';
import { InputComponent } from '../CustomInput';

const getPayload = (value: number | string | null, isNullable: boolean): number | string | null => {
	let payload = value;
	if (payload) {
		payload = +payload;
	} else {
		payload = isNullable ? null : '';
	}
	return payload;
};

interface CustomInputMaskProps
	extends Omit<BaseTextFieldProps, 'label' | 'name' | 'onChange' | 'value' | 'onBlur'>,
		Pick<Props, 'mask' | 'maskChar' | 'maskPlaceholder'>,
		DefaultFieldProps<string> {
	startIcon?: ReactNode;
	endIcon?: ReactNode;
	isNullable?: boolean;
}

export const InputMaskComponent: FC<CustomInputMaskProps> = ({
	value,
	mask,
	onChange,
	placeholder,
	onBlur,
	maskPlaceholder = null,
	onOpen,
	onDelete,
	disabled,
	name,
	isNullable = false,
	form,
	field,
	...props
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(event);
		} else if (form && name) {
			const payload: number | string | null =
				mask === MASK.NUMBER ? getPayload(event.target.value, isNullable) : event.target.value;
			form.setFieldValue(name, payload);
		}
	};

	return (
		<InputMask
			// for example 99/99/9999, aaa999 or [/[0-2]/, /[as]/]. Look: https://www.npmjs.com/package/react-input-mask/v/3.0.0-alpha.2
			mask={mask}
			id={name}
			maskPlaceholder={maskPlaceholder}
			value={value && mask === MASK.NUMBER ? value.toString() : value ?? ''}
			onChange={handleChange}
			onBlur={onBlur}
			disabled={disabled}
		>
			<InputComponent {...props} name={name} placeholder={placeholder} />
		</InputMask>
	);
};

export const CustomInputMask = withField(InputMaskComponent);
