import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getUserDataSelector, getUserIsLoadingSelector } from 'store/user';
import { SignupForm } from 'components/Auth';
import { Loader } from 'components/shared';
import { getMainRoute, getSigninRoute } from 'utils/helpers';
import { IUserResponse } from 'utils/types';
import styles from './index.module.scss';

export const SignupPage: FC = () => {
	const isLoading: boolean = useSelector(getUserIsLoadingSelector);
	const userData: IUserResponse | null = useSelector(getUserDataSelector);

	useEffect(() => {
		if (userData !== undefined && !isLoading) {
			getMainRoute();
		}
	}, [isLoading, userData]);

	return (
		<div className={styles.content}>
			<Typography variant="h3">Create account</Typography>
			<Typography className={styles.content__description} variant="body1">
				Welcome! Please enter your details.
			</Typography>
			<SignupForm />
			<Typography className={styles.content__subtitle} variant="body1">
				Already have an account?
			</Typography>
			<Link to={getSigninRoute()}>
				<Typography variant="button">Login</Typography>
			</Link>
			{isLoading && <Loader />}
		</div>
	);
};
