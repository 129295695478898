/* eslint-disable @typescript-eslint/no-explicit-any */
export const stringDecoder = (inputString: any) => {
	const decodedString = inputString.toLowerCase().replace(/_/g, ' ');
	const words = decodedString.split(' ');
	const capitalizedWords = words.map((word: any) => {
		const firstLetter = word.charAt(0).toUpperCase();
		const restOfWord = word.slice(1);
		return firstLetter + restOfWord;
	});
	return capitalizedWords.join(' ');
};
