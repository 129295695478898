import { FC } from 'react';
import { useParams } from 'react-router';
import { UpdateScenario } from 'components';
import styles from './index.module.scss';

export const UpdatePage: FC = () => {
	const { scenarioId } = useParams();
	return (
		<div className={styles.content}>
			<div className={styles.wrapper}>
				<UpdateScenario scenarioId={scenarioId} />
			</div>
		</div>
	);
};
