import { ROUTES } from 'config';

export const getSignupRoute = (): string => `/${ROUTES.SIGN_UP}`;

export const getSigninRoute = (): string => `/${ROUTES.LOGIN}`;

export const getMainRoute = (): string => `${ROUTES.MAIN}`;

export const getCreateNewRoute = (): string => `/${ROUTES.NEW_SCENARIO}`;

export const getSettingsRoute = (): string => `/${ROUTES.SETTINGS}`;

export const getBulkSendRoute = (): string => `/${ROUTES.BULK_SEND}`;

export const getUpdateScenatioRoute = (scenarioId: string): string => `/${ROUTES.UPDATE}/${scenarioId}`;
