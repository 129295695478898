/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ROUTES } from 'config/routes';
import { logoutUser } from 'store/user';
import { CustomButton } from 'components/shared';
import { getSigninRoute, getSignupRoute } from 'utils/helpers';
import styles from './index.module.scss';

export const Header = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currentPath = location.pathname.slice(1);

	const isLoginPage = currentPath === ROUTES.LOGIN ? 1 : 0;
	const isAuthorize = currentPath !== ROUTES.LOGIN && currentPath !== ROUTES.SIGN_UP ? 1 : 0;

	const unAuthorizeAction = () => navigate(isLoginPage ? getSignupRoute() : getSigninRoute());

	const authorizeAction = async (): Promise<void> => {
		await dispatch(logoutUser());
		navigate(ROUTES.LOGIN);
	};

	return (
		<header id="header-main" className={styles.wrapper}>
			<Box className={styles.content}>
				<Typography variant="h3" className={styles.title} sx={{ fontSize: '20px' }}>
					TELEGRAM BOT GENERATOR
				</Typography>
				<Box>
					<CustomButton
						label={isAuthorize ? 'Log Out' : isLoginPage ? 'Sign Up' : 'Sign In'}
						className={styles.button}
						onClick={() => (isAuthorize ? authorizeAction() : unAuthorizeAction())}
					/>
				</Box>
			</Box>
		</header>
	);
};
