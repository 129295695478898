import { FC, HTMLInputTypeAttribute, ReactNode, useState } from 'react';
import { BaseTextFieldProps, IconButton, InputAdornment, OutlinedTextFieldProps, TextField } from '@mui/material';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as EyeOff } from 'assets/icons/eye-off.svg';
import { withField } from 'hoc/withField';
import { DefaultFieldProps } from 'utils/types';

export interface CustomInputProps<T>
	extends Omit<BaseTextFieldProps, 'label' | 'name' | 'onChange' | 'value' | 'onBlur'>,
		Pick<OutlinedTextFieldProps, 'InputProps'>,
		DefaultFieldProps<T> {
	startIcon?: ReactNode;
	endIcon?: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InputComponent: FC<CustomInputProps<any>> = ({
	variant = 'outlined',
	type = 'text',
	endIcon = undefined,
	startIcon = undefined,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	open,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onOpen,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onClose,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onDelete,
	value = '',
	...props
}) => {
	const [inputType, setInputType] = useState<HTMLInputTypeAttribute>(type);

	const toogleInputPassword = () => {
		setInputType(inputType === 'text' ? 'password' : 'text');
	};

	return (
		<TextField
			{...(props as BaseTextFieldProps)}
			value={value ?? ''}
			variant={variant}
			type={inputType}
			InputProps={{
				endAdornment: (endIcon || type === 'password') && (
					<InputAdornment position="end">
						{endIcon || (
							<IconButton onClick={toogleInputPassword}>{inputType === 'password' ? <EyeOff /> : <Eye />}</IconButton>
						)}
					</InputAdornment>
				),
				startAdornment: startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>,
			}}
			InputLabelProps={{ shrink: false }}
		/>
	);
};

export const CustomInput = withField(InputComponent);
