import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ReactComponent as BezierIcon } from 'assets/icons/bezier.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as BulkSendIcon } from 'assets/icons/transit.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/setting.svg';
import { ROUTES } from 'config';
import { getCreateNewRoute, getMainRoute, getSettingsRoute, getBulkSendRoute } from 'utils/helpers';
import styles from './index.module.scss';

export const Aside = () => {
	const location = useLocation();
	const currentPath = location.pathname.length === 1 ? '/' : location.pathname.slice(1);

	return (
		<aside className={styles.wrapper}>
			<Link to={getMainRoute()}>
				<Box className={currentPath === ROUTES.MAIN ? styles.item_active : ''} sx={{ display: 'flex' }}>
					<HomeIcon />
					<Typography className={currentPath === ROUTES.MAIN ? styles.menu_item_active : styles.menu_item}>
						Home
					</Typography>
				</Box>
			</Link>
			<Link to={getCreateNewRoute()}>
				<Box className={currentPath === ROUTES.NEW_SCENARIO ? styles.item_active : ''} sx={{ display: 'flex' }}>
					<BezierIcon />
					<Typography className={currentPath === ROUTES.NEW_SCENARIO ? styles.menu_item_active : styles.menu_item}>
						Create New
					</Typography>
				</Box>
			</Link>
			<Link to={getBulkSendRoute()}>
				<Box className={currentPath === ROUTES.BULK_SEND ? styles.item_active : ''} sx={{ display: 'flex' }}>
					<BulkSendIcon />
					<Typography className={currentPath === ROUTES.BULK_SEND ? styles.menu_item_active : styles.menu_item}>
						Bulk Send Message
					</Typography>
				</Box>
			</Link>
			<Link to={getSettingsRoute()}>
				<Box className={currentPath === ROUTES.SETTINGS ? styles.item_active : ''} sx={{ display: 'flex' }}>
					<SettingIcon />
					<Typography className={currentPath === ROUTES.SETTINGS ? styles.menu_item_active : styles.menu_item}>
						Settings
					</Typography>
				</Box>
			</Link>
		</aside>
	);
};
