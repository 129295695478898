import { FC } from 'react';
import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface CustomModalProps extends Omit<DialogProps, 'open' | 'onClose'> {
	title?: string;
	onClose: () => void;
	isOpen: boolean;
}

export const CustomModal: FC<CustomModalProps> = ({ isOpen, onClose, title, children }) => (
	<Dialog open={isOpen} onClose={onClose}>
		<DialogTitle>
			{title}
			<IconButton
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: '16px',
					top: '16px',
				}}
			>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
		<DialogContent>{children}</DialogContent>
	</Dialog>
);
