/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-can.svg';
import { CustomButton, CustomIconButton, CustomInput } from 'components/shared';
import { CustomSelect } from 'components/shared/CustomSelect';
import { BUTTON_VARIANT, COMMON_FIELD_NAMES, SCENARIO_OPTION_INITIAL_VALUES } from 'utils/constants';
import { DefaultFieldProps } from 'utils/types';
import styles from './index.module.scss';

interface ScheduleField extends DefaultFieldProps<any> {
	name: string;
	value: any;
	action: any;
}

export const ScenarioButtonsField: FC<ScheduleField> = ({ name, value, action }) => {
	const initState1 = value.options[0]?.buttonText ? 1 : null;
	const initState2 = value.options[0]?.scenarioName ? 0 : null;
	const firstCheck = initState1 === 1;
	const secondCheck = initState2 === 0;
	const [isButtons, setIsButtons] = useState<number | null>(initState1 || initState2);
	const [isFirstContact, setIsFirstContact] = useState<boolean>(false);

	const selectHandler = (value: any, callback: any) => {
		if (!isFirstContact && !firstCheck && !secondCheck) {
			setIsButtons(value);
			setIsFirstContact(true);
			callback();
		}
	};

	return (
		<FieldArray
			name={name}
			render={({ remove, push, replace }) => (
				<>
					<CustomSelect
						label="Select the option about having buttons in the message"
						placeholder="Select option"
						onChange={value => selectHandler(value.target.value, () => push(SCENARIO_OPTION_INITIAL_VALUES))}
						value={isButtons}
						options={[
							{ value: 0, title: 'No Buttons' },
							{ value: 1, title: 'Buttons' },
						]}
						isDefault={false}
						disabled={isFirstContact || firstCheck || secondCheck}
						className={styles.button_add}
					/>
					{!!value.options?.length &&
						value.options.map((item: any, index: number) => (
							<div key={index} className={styles.wrapper}>
								{!!isButtons && (
									<Field
										label="Button text"
										name={`${name}.${index}.${COMMON_FIELD_NAMES.SCENARIO_OPTION_TEXT}`}
										component={CustomInput}
										disabled={item.toEdit}
									/>
								)}
								{isButtons === 0 ? (
									<Field
										label="Scenario name"
										name={`${name}.${index}.${COMMON_FIELD_NAMES.SCENARIO_OPTION_SCENARIO_NAME}`}
										component={CustomInput}
										disabled={item.toEdit}
									/>
								) : null}
								{!!isButtons && (
									<Field
										label="URL"
										name={`${name}.${index}.${COMMON_FIELD_NAMES.SCENARIO_OPTION_URL}`}
										component={CustomInput}
										disabled={item.toEdit}
									/>
								)}
								{!!isButtons && !item?.toEdit && (
									<CustomIconButton
										className={styles.button_delete}
										icon={<DeleteIcon />}
										onClick={() => remove(index)}
									/>
								)}
							</div>
						))}

					{!!isButtons && (
						<CustomButton
							className={styles.button_add}
							label="Add button"
							onClick={() => push(SCENARIO_OPTION_INITIAL_VALUES)}
							variant={BUTTON_VARIANT.OUTLINED}
						/>
					)}
				</>
			)}
		/>
	);
};
