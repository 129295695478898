/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ROUTES } from 'config/routes';
import { TOASTIFY_TYPES, USER_MESSAGE } from 'utils/constants/toastify';
import { VAULT_SERVICE_KEYS } from 'utils/constants/vault-service';
import { refreshToken } from 'utils/helpers/refresh-token';
import { toastifyMessage } from 'utils/helpers/toastify';
import { VaultService } from 'utils/services/vault.service';

const axiosConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_URL,
};

const instance: AxiosInstance = axios.create(axiosConfig);

instance.interceptors.request.use(
	async (config: AxiosRequestConfig) => {
		const accessToken = VaultService.getItem<string>(VAULT_SERVICE_KEYS.ACCESS_TOKEN);

		if (accessToken && config.headers) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},

	(error: AxiosError<string>) => {
		throw error;
	}
);

instance.interceptors.response.use(
	async (res: AxiosResponse) => res,

	async (error: AxiosError<string>) => {
		const status: number | undefined = error.response?.status;
		if (status === 409) {
			toastifyMessage(USER_MESSAGE.USER_NOT_FOUND, TOASTIFY_TYPES.ERROR);
			return Promise.reject(error);
		}

		return Promise.reject(error);
	}
);

export const API = instance;
