/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Field, Form, Formik } from 'formik';
import { ReactComponent as ArrowSvg } from 'assets/icons/arrow.svg';
import { postNewScenario, updateScenario } from 'store/scenario';
import { CustomButton, CustomIconButton, CustomInput, FieldWrapper } from 'components/shared';
import {
	COMMON_FIELD_NAMES,
	COMMON_MESSAGE,
	DEFAULT_COMMANDS,
	SCENARIO_INITIAL_VALUES,
	TOASTIFY_TYPES,
} from 'utils/constants';
import {
	convertArrayToObject,
	createNextScenario,
	deleteElementAndChildren,
	getMainRoute,
	toastifyMessage,
	transformObjectToInitial,
} from 'utils/helpers';
import { scenarioValidationSchema } from 'utils/validation';
import { ScenarioItemsField } from '../ScenarioItemsField';
import styles from './index.module.scss';

interface BotScenarioFieldProps {
	initialValues?: any;
	scenarioId?: any;
}

export const BotScenarioField: FC<BotScenarioFieldProps> = ({ initialValues, scenarioId }) => {
	const [actualValues, setActualValues] = useState<any>(transformObjectToInitial(initialValues));
	const [isFieldsVisible, setIsFieldsVisible] = useState(true);

	const handleAccordionToggle = () => {
		setIsFieldsVisible(!isFieldsVisible);
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const submitHanlder = (value: any): void => {
		const withNextScenarios = createNextScenario(value.body);
		const convertedObject = convertArrayToObject(withNextScenarios);
		const finalBody = { commands: DEFAULT_COMMANDS, scenarios: convertedObject };
		const clearRes = { ...value, body: finalBody };
		dispatch(initialValues ? updateScenario(clearRes, scenarioId) : postNewScenario(clearRes));
		if (initialValues) {
			navigate(getMainRoute());
		}
	};

	const deleteItemHandler = (values: any, index: number, setActiveItem: any) => {
		if (!index) {
			toastifyMessage(COMMON_MESSAGE.DELETE_INTRO, TOASTIFY_TYPES.ERROR);
			return;
		}
		setActiveItem(0);
		setActualValues(deleteElementAndChildren(values.body, index));
		toastifyMessage(COMMON_MESSAGE.DELETE_SCENARIO, TOASTIFY_TYPES.SUCCESS);
	};

	return (
		<Formik
			initialValues={actualValues || SCENARIO_INITIAL_VALUES}
			onSubmit={submitHanlder}
			validationSchema={scenarioValidationSchema}
		>
			{() => {
				return (
					<Form className={styles.wrapper}>
						<FieldWrapper
							title={initialValues ? `Editing ${initialValues.name}` : 'New Bot Scenario'}
							description={`${initialValues ? 'Update' : 'Enter '} information about your bot scenario`}
							isStepper
						>
							<Accordion defaultExpanded>
								<AccordionSummary expandIcon={<ArrowSvg />} onClick={handleAccordionToggle} />
								<AccordionDetails>
									{!!isFieldsVisible && (
										<Box className={styles.top_side}>
											<Field
												name={COMMON_FIELD_NAMES.SCENARIO_NAME}
												component={CustomInput}
												label="Name"
												id="name"
												isShowErrorText
											/>
											<Field
												name={COMMON_FIELD_NAMES.SCENARIO_DESCRIPTION}
												component={CustomInput}
												label="Description"
												id="description"
												isShowErrorText
											/>
										</Box>
									)}
								</AccordionDetails>
							</Accordion>
							<ScenarioItemsField deleteItemsHandler={deleteItemHandler} />
							<Box className={styles.submit_button}>
								<CustomButton type="submit" label={initialValues ? 'Save schema' : 'Save schema'} />
							</Box>
						</FieldWrapper>
					</Form>
				);
			}}
		</Formik>
	);
};
