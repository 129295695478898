/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllScenarioData } from 'store/scenario';
import {
	getAllScenarioDataSelector,
	getFavoriteLoadingSelector,
	getScenarioIsLoadingSelector,
} from 'store/scenario/selectors';
import { getUserData, getUserDataSelector, getUserIsLoadingSelector } from 'store/user';
import { Loader } from 'components';
import { ActiveScenarioComponent, MyScenarios, UserInfoComponent } from 'components/Home';
import { IBotScenario } from 'utils/types';
import styles from './index.module.scss';

export const HomePage: FC = () => {
	const [isUpdate, setIsUpdate] = useState(false);
	const dispatch = useDispatch();

	const allScenarios: IBotScenario[] | null = useSelector(getAllScenarioDataSelector);
	const userData: any = useSelector(getUserDataSelector);

	const isLoadingScenarios: boolean = useSelector(getScenarioIsLoadingSelector);
	const isLoadingUser: boolean = useSelector(getUserIsLoadingSelector);

	const isBotSetted: boolean = userData.botToken || userData.botName;

	const notActiveScenarios = allScenarios?.filter(item => item._id !== userData.favoriteScenario);
	const activeScenario = allScenarios?.find(item => item._id === userData.favoriteScenario);

	useEffect(() => {
		dispatch(getAllScenarioData());
		dispatch(getUserData());
	}, [isUpdate]);

	return (
		<>
			<div className={styles.content}>
				<div className={styles.wrapper}>
					<div className={styles.upper_half}>
						<UserInfoComponent data={userData} />
						<ActiveScenarioComponent data={activeScenario} onChange={setIsUpdate} status={isUpdate} />
					</div>
					<div className={styles.lower_half}>
						<MyScenarios data={notActiveScenarios} onChange={setIsUpdate} status={isUpdate} botStatus={isBotSetted} />
					</div>
				</div>
			</div>
			{(isLoadingScenarios || isLoadingUser) && <Loader />}
		</>
	);
};
