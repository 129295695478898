/* eslint-disable react/no-danger */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/edit.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as TransitIcon } from 'assets/icons/transit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-can.svg';
import { CustomButton, CustomIconButton } from 'components/shared';
import {
	BUTTON_VARIANT,
	COMMON_FIELD_NAMES,
	COMMON_MESSAGE,
	SCENARIO_ITEM_INITIAL_VALUES,
	TOASTIFY_TYPES,
} from 'utils/constants';
import { findIndexById, stringCoder, stringDecoder, transformKeyForRightUi } from 'utils/helpers';
import { useModal } from 'utils/hooks';
import { IUseModal } from 'utils/types';
import { ScenarioItemModal } from '../ScenarioItemModal';
import styles from './index.module.scss';

interface ScenarioItemsFieldProps {
	deleteItemsHandler: any;
}

export const ScenarioItemsField: FC<ScenarioItemsFieldProps> = ({ deleteItemsHandler }) => {
	const { values } = useFormikContext<any>();
	const [activeItem, setActiveItem] = useState<number>(0);

	const [isOpen, onOpen, onClose]: IUseModal = useModal();

	const openCurrentItem = () => {
		onOpen();
	};

	const openNextItem = (index: number) => {
		setActiveItem(index);
	};

	const handleStepBack = (parentId: number) => {
		if (activeItem) {
			const prevIndex = findIndexById(values.body, parentId);
			setActiveItem(prevIndex);
		}
	};

	// FE: intro
	const activeItemObjkey = Object.keys(values.body[activeItem])[0];

	const idOfCurrentItem = values.body[activeItem][activeItemObjkey].id;
	const parentIdOfCurrentItem = values.body[activeItem][activeItemObjkey].parentId;
	const readIndexOfCurrentItem = values.body[activeItem][activeItemObjkey].realIndex;
	const photoOfCurrentItem = values.body[activeItem][activeItemObjkey].photo;
	const textOfCurrentItem = values.body[activeItem][activeItemObjkey].text;
	const urlOfCurrentItem = (index: number) => values.body[activeItem][activeItemObjkey].options[index]?.url;

	return (
		<FieldArray
			name={COMMON_FIELD_NAMES.SCENARIO_BODY}
			render={({ push, remove, replace }: ArrayHelpers) => (
				<>
					<div className={styles.container}>
						<div className={styles.wrapper}>
							<div className={styles.inner}>
								<CustomIconButton icon={<ArrowBack />} onClick={() => handleStepBack(parentIdOfCurrentItem)} />
								<CustomButton
									variant={BUTTON_VARIANT.OUTLINED}
									label={stringDecoder(transformKeyForRightUi(activeItemObjkey))}
									onClick={() => openCurrentItem()}
									endIcon={<PencilIcon />}
								/>
								<CustomIconButton
									icon={<TrashIcon />}
									onClick={() => deleteItemsHandler(values, readIndexOfCurrentItem, setActiveItem)}
								/>
							</div>
							{!!values.body[activeItem][activeItemObjkey].photo && (
								<img className={styles.image} src={photoOfCurrentItem} alt="" />
							)}
							<div className={styles.post_message} dangerouslySetInnerHTML={{ __html: textOfCurrentItem }} />
							<div className={styles.post_buttons}>
								{values.body
									.filter((item: any) => {
										const objKey = Object.keys(item)[0];
										return item[objKey].parentId === idOfCurrentItem;
									})
									.map((item: any, index: number) => {
										const objKey = Object.keys(item)[0];
										return (
											<CustomButton
												variant={BUTTON_VARIANT.OUTLINED}
												label={stringDecoder(transformKeyForRightUi(Object.keys(item)[0]))}
												onClick={() =>
													urlOfCurrentItem(index)
														? window.open(urlOfCurrentItem(index), '_blank')
														: openNextItem(item[objKey].realIndex)
												}
												style={{ margin: '0px 16px' }}
												key={item.id}
												className={styles.post_buttons_item}
												endIcon={urlOfCurrentItem(index) ? <LinkIcon /> : <TransitIcon />}
											/>
										);
									})}
							</div>
						</div>
					</div>
					<ScenarioItemModal
						isOpen={isOpen}
						onClose={onClose}
						initialValues={values.body[activeItem][activeItemObjkey]}
						replace={replace}
						activeItem={activeItem}
						push={push}
						itemsArrayLength={values.body.length}
						itemsArray={values.body}
						isIntro={activeItemObjkey === 'intro'}
					/>
				</>
			)}
		/>
	);
};
