import { AnyAction, Reducer } from '@reduxjs/toolkit';
import { IScenarioReducer } from 'utils/types';
import { ACTION_TYPES } from './actions';

const initialState: IScenarioReducer = {
	data: null,
	favoriteScenario: null,
	isLoading: false,
	isLoadingFavorite: false,
};

export const scenarioReducer: Reducer<IScenarioReducer> = (
	state: IScenarioReducer = initialState,
	action: AnyAction
) => {
	const { type, payload } = action;

	switch (type) {
		case ACTION_TYPES.SCENARIO_LOADING:
			return {
				...state,
				isLoading: payload,
			};
		case ACTION_TYPES.SCENARIO_GET_DATA:
			return {
				...state,
				isLoading: false,
				data: payload,
			};
		case ACTION_TYPES.FAVORITE_SCENARIO:
			return {
				...state,
				isLoadingFavorite: false,
				faviriteScenario: payload,
			};

		default:
			return state;
	}
};
