import { FC } from 'react';
import { Outlet } from 'react-router';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import styles from './index.module.scss';

export const AuthContainer: FC = () => (
	<div className={styles.container}>
		<Header />
		<main>
			<Outlet />
		</main>
		<Footer />
	</div>
);
