import * as yup from 'yup';
import { COMMON_FIELD_NAMES } from 'utils/constants';
// import { scenarioItemValidationSchema } from './scenario-item.schema';

export const scenarioValidationSchema = yup.object().shape({
	[COMMON_FIELD_NAMES.SCENARIO_NAME]: yup.string().required('Required field').nullable(),
	[COMMON_FIELD_NAMES.SCENARIO_DESCRIPTION]: yup.string().required('Required field').nullable(),
});

export const scenarioFormSchema = yup.object().shape({
	[COMMON_FIELD_NAMES.SCENARIO_ITEM_TEXT]: yup.string().required('Required field').nullable(),
});
