import { useState } from 'react';
import { IUseOpen } from 'utils/types';

export const useOpen: (openDefault?: boolean) => IUseOpen = (openDefault = false): IUseOpen => {
	const [isOpen, setIsOpen] = useState(openDefault);

	const onOpen = () => setIsOpen(true);

	const onClose = () => setIsOpen(false);

	return { open: isOpen, onOpen, onClose };
};
