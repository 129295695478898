/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { Typography } from '@mui/material';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as BookmarkSvg } from 'assets/icons/bookmark.svg';
import { CustomButton, CustomModal } from 'components/shared';
import { BUTTON_VARIANT, SCENARIO_ITEM_INITIAL_VALUES } from 'utils/constants';
import { findKeyByIndex, stringCoder, transformUUIDv4 } from 'utils/helpers';
import { ModalProps } from 'utils/types';
import { scenarioFormSchema } from 'utils/validation';
import { ScenarioForm } from '../ScenarioForm';
import styles from './index.module.scss';

interface ScenarioItemModalProps extends ModalProps {
	replace: any;
	initialValues: any;
	activeItem: number;
	itemsArray: any[];
	push: any;
	itemsArrayLength: number;
	isIntro: boolean;
}

export const ScenarioItemModal: FC<ScenarioItemModalProps> = ({
	replace,
	isOpen,
	onClose,
	initialValues,
	activeItem,
	itemsArray,
	push,
	itemsArrayLength,
	isIntro,
}) => {
	const handleSubmit = (values: any): void => {
		onClose();

		const currentItemKey: any = findKeyByIndex(itemsArray, activeItem);
		values.options.forEach((element: any) => {
			if (!element.id) {
				element.id = transformUUIDv4(uuidv4());
			}
		});

		const updatedModalContent = activeItem ? { ...values, isNew: false } : { ...values, id: 'intro', isNew: false };
		const parentId = updatedModalContent.id;

		updatedModalContent.options
			.filter((option: any) => option?.toEdit === false || option?.toEdit === undefined)
			.forEach((option: any, index: number) => {
				if (!option?.toEdit) {
					const firstPartOfKey = option.buttonText ? stringCoder(option.buttonText) : stringCoder(option.scenarioName);
					// eslint-disable-next-line prefer-template
					const key = firstPartOfKey + '_' + option.id;
					const newItemValue = {
						...SCENARIO_ITEM_INITIAL_VALUES,
						parentId,
						realIndex: itemsArrayLength + index,
						id: values.options[index].id,
						isNew: true,
					};
					push({ [key]: newItemValue });
				}
			});

		const finalUpdatedPropery = JSON.parse(JSON.stringify(updatedModalContent));

		finalUpdatedPropery.options.forEach((element: any) => {
			element.toEdit = true;
		});

		const updatedProperty = { [currentItemKey]: finalUpdatedPropery };
		replace(activeItem, updatedProperty);
	};

	return (
		<CustomModal onClose={onClose} isOpen={isOpen}>
			<Typography className={styles.title} variant="h4">
				{initialValues?.isNew ? 'Add scenario item' : 'Edit scenario item'}
			</Typography>
			<Formik initialValues={initialValues} onSubmit={handleSubmit}>
				{({ values, handleSubmit }) => (
					<>
						<ScenarioForm value={values} replace={replace} isIntro={isIntro} />
						<div className={styles.actions}>
							<CustomButton label="Cancel" onClick={onClose} variant={BUTTON_VARIANT.OUTLINED} />
							<CustomButton
								label={initialValues?.isNew ? 'Save' : 'Save'}
								onClick={handleSubmit}
								endIcon={<BookmarkSvg />}
							/>
						</div>
					</>
				)}
			</Formik>
		</CustomModal>
	);
};
