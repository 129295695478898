/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
export const transformObjectToInitial = (input: any) => {
	if (input) {
		const { name, description, body } = input;
		const transformedBody = Object.entries(body.scenarios).map(([key, value]) => ({ [key]: value }));

		return {
			name,
			description,
			body: transformedBody,
		};
	}
};
