/* eslint-disable react/jsx-no-useless-fragment */
import { useSelector } from 'react-redux';
import { getUserDataSelector } from 'store/user/selectors';
import { SettingsField } from 'components/Fields';

export const Settings = () => {
	const userData = useSelector(getUserDataSelector);

	return <>{userData.botName || userData.botToken ? <SettingsField initialValues={userData} /> : <SettingsField />}</>;
};
