/* eslint-disable @typescript-eslint/no-explicit-any */

export const createNextScenario = (array: any[]) =>
	array.map(item => {
		const newItem: any = {};
		Object.entries(item).forEach(([key, subItem]) => {
			const subItemObj = subItem as Record<string, any>;
			const options = subItemObj.options.map((option: any) => ({
				...option,
				buttonText: option.buttonText,
				nextScenario: option.id,
			}));
			newItem[key] = { ...subItemObj, options };
		});
		return newItem;
	});
