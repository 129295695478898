import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { scenarioReducer } from 'store/scenario/reducer';
import { settingsReducer } from 'store/settings/reducer';
import { userReducer } from 'store/user/reducer';

export const store = configureStore({
	reducer: {
		user: userReducer,
		scenario: scenarioReducer,
		settings: settingsReducer,
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;
