/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { COMMON_MESSAGE, TOASTIFY_TYPES } from 'utils/constants';
import { toastifyMessage } from 'utils/helpers';
import { SettingsService } from 'utils/services';

export enum ACTION_TYPES {
	SETTINGS_LOADING = 'SETTINGS_LOADING',
	FAVORITE_SCENARIO = 'FAVORITE_SCENARIO',
}

export const changeScenarioIsLoading = (payload: boolean): AnyAction => ({
	type: ACTION_TYPES.SETTINGS_LOADING,
	payload,
});

export const postSettings =
	(reqBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeScenarioIsLoading(true));
			await SettingsService.postSettings(reqBody);
			toastifyMessage(COMMON_MESSAGE.SUCCESS, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			await Promise.reject(error);
			toastifyMessage(COMMON_MESSAGE.SOMETHING_WENT_WRONG, TOASTIFY_TYPES.ERROR);
		} finally {
			dispatch({ type: ACTION_TYPES.SETTINGS_LOADING, payload: false });
		}
	};
