import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getSettingsIsLoadingSelector } from 'store/settings';
import { Loader, BulkSendFormComponent } from 'components';
import styles from './index.module.scss';

export const BulkSendPage: FC = () => {
	const isLoading: boolean = useSelector(getSettingsIsLoadingSelector);
	return (
		<>
			<div className={styles.content}>
				<BulkSendFormComponent data={null} />
			</div>
			{isLoading && <Loader />}
		</>
	);
};
