import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { getUserIsLoadingSelector, loginUser } from 'store/user';
import { CustomButton, CustomInput } from 'components/shared';
import { COMMON_FIELD_NAMES } from 'utils/constants';
import { ILoginRequest } from 'utils/types';
import { loginValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

const LOGIN_INITIAL_VALUES: ILoginRequest = {
	email: '',
	password: '',
};

export const LoginForm: FC = () => {
	const dispatch = useDispatch();

	const isLoading: boolean = useSelector(getUserIsLoadingSelector);

	const handleSubmit = async (values: ILoginRequest): Promise<void> => {
		await dispatch(loginUser(values));
	};

	return (
		<Formik initialValues={LOGIN_INITIAL_VALUES} onSubmit={handleSubmit} validationSchema={loginValidationSchema}>
			<Form>
				<div className={styles.field}>
					<Field
						placeholder="example@mail.com"
						label="Email"
						id="email"
						name={COMMON_FIELD_NAMES.EMAIL}
						component={CustomInput}
						isShowErrorText
					/>
				</div>
				<div className={styles.field}>
					<Field
						placeholder="********"
						label="Password"
						id="password"
						name={COMMON_FIELD_NAMES.PASSWORD}
						type="password"
						component={CustomInput}
						isShowErrorText
					/>
				</div>
				{/* <ForgotPassword /> */}
				<CustomButton className={styles.button_login} type="submit" disabled={isLoading} label="Login" />
			</Form>
		</Formik>
	);
};
